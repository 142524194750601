import React from "react";

const MainLayout = ({ children }) => {
  return (
    <div>
      <header>{/* Header content here */}</header>
      <main>{children}</main>
      <footer>{/* Footer content here */}</footer>
    </div>
  );
};

export default MainLayout;
