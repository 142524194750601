import React, { useState } from "react";

const Tab = ({ children, label }) => {
  return <div>{children}</div>;
};

const Tabs = ({ children }) => {
  const tabsArray = React.Children.toArray(children);

  // Set initial active tab state to the label of the first tab if available, or null
  const [activeTab, setActiveTab] = useState(
    tabsArray.length > 0 ? tabsArray[0].props.label : null
  );

  const handleClick = (e, newActiveTab) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
  };

  // If there are no tabs, return null or a placeholder
  if (tabsArray.length === 0) {
    return null; // Or return a default layout or a warning message
  }

  return (
    <div>
      <ul className="tab-headers">
        {tabsArray.map((tab) => (
          <li
            key={tab.props.label}
            className={tab.props.label === activeTab ? "active" : ""}
            onClick={(e) => handleClick(e, tab.props.label)}
          >
            {tab.props.label}
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {tabsArray.map((tab) => {
          if (tab.props.label !== activeTab) return undefined;
          return tab.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
export { Tab };
