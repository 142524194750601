import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../AuthProvider";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useSessionData } from "../SessionDataProvider";
import { useTokenTracker } from "../TokenTrackerContext";
import LoginForm from "../components/LoginForm";
import AdminDashboard from "../components/AdminDashboard";
import Tabs, { Tab } from "../components/Tabs";
import AppBarComponent from "../components/AppBarComponent";
import DrawerComponent from "../components/DrawerComponent";
import WriteTab from "../components/WriteTab";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme/theme";
import SummarizeTab from "../components/SummarizeTab";
import PdfDocSummaryTab from "../components/PdfDocSummaryTab";
import TakeNotesTab from "../components/TakeNotesTab";
import CreateImageTab from "../components/CreateImageTab";
import TranscribeAudioTab from "../components/TranscribeAudioTab";
import "./HomePage.css";
import { handleTaskRequest } from "../utils/taskUtils";
import { useTask } from "../TaskContext";

// Material-UI Imports
import Box from "@mui/material/Box";

const HomePage = () => {
  const { isAuthenticated, userRole } = useAuth();
  const [showDashboard, setShowDashboard] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [generatedText, setGeneratedText] = useState("");
  const { exchangeRate } = useExchangeRate(); // Access exchange rate info
  const [scratchPadText, setScratchPadText] = useState("");
  const [urlScratchPadText, setUrlScratchPadText] = useState("");
  const [chatHistory, setChatHistory] = useState("");
  const [sourceText, setSourceText] = useState("");
  const [url, setUrl] = useState("");
  const [generatedUrlSummary, setGeneratedUrlSummary] = useState("");
  const [urlSummariesHistory, setUrlSummariesHistory] = useState("");
  const [nonChatDraftHistory, setNonChatDraftHistory] = useState("");
  const [draftHistory, setDraftHistory] = useState("");
  const [brainstormHistory, setBrainstormHistory] = useState("");
  const [isNewChatSession, setIsNewChatSession] = useState(true);
  const [taskChoice, setTaskChoice] = useState(""); // Default option
  const [currentTask, setCurrentTask] = useState("");
  const [llmChoice, setLlmChoice] = useState(""); // State for the LLM choice
  const [languageChoice, setLanguageChoice] = useState("Tagalog"); // State for the language choice
  const [drawerOpen, setDrawerOpen] = useState(false); // State for drawer
  const { updateTokenData } = useTokenTracker();
  const [tokenInfo, setTokenInfo] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [summaryStyleChoice, setSummaryStyleChoice] = useState("Bullets");
  const [summaryStyle, setSummaryStyle] = useState("BulletPoints");
  const [fileUrl, setFileUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(0.6);
  const [finalSummary] = useState("");
  const [combinedSummary, setCombinedSummary] = useState("");
  const [segmentSummaries] = useState("");
  const [outputHistory, setOutputHistory] = useState("");
  const [notesFileUrl, setNotesFileUrl] = useState("");
  const [notesNumPages, setNotesNumPages] = useState(0);
  const [notesPageNumber, setNotesPageNumber] = useState(1);
  const [notesScale, setNotesScale] = useState(1);
  const [notesMessages, setNotesMessages] = useState([]);
  const { sessionData, setSessionData } = useSessionData();
  const [userSelectedLLM, setUserSelectedLLM] = useState(false);
  const [imageModel, setImageModel] = useState("fal-ai/flux-realism");
  const [imagePrompt, setImagePrompt] = useState("");
  const [generatedImageUrl, setGeneratedImageUrl] = useState("");
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [enhancePrompt, setEnhancePrompt] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [transformedImageUrl, setTransformedImageUrl] = useState("");
  const [imageTask, setImageTask] = useState("create");
  const [audioTask, setAudioTask] = useState("transcribe");
  const [audioLanguage, setAudioLanguage] = useState("tl");
  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [transcriptionResult, setTranscriptionResult] = useState("");
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  //const handleToggleForms = () => setShowLoginForm(!showLoginForm); // Function to toggle forms
  const { setUsageTask, updateTaskUsage } = useTask();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleDashboard = () => {
    if (userRole === "admin") {
      setShowDashboard(!showDashboard);
    }
  };

  const handleLlmChoiceChange = useCallback(
    (e) => {
      setUserSelectedLLM(true);
      setLlmChoice(e.target.value);
    },
    [setUserSelectedLLM, setLlmChoice]
  ); // Dependencies necessary for useCallback

  const handleTaskChoiceChange = (e) => {
    setTaskChoice(e.target.value);
  };

  useEffect(() => {
    // Automatically set LLM choice based on the selected task
    switch (taskChoice) {
      case "Freeform":
      case "Summary/Outline":
      case "House Bill":
      case "House Resolution":
      case "Explanatory Note":
      case "Chat":
      case "Brainstorm":
        setLlmChoice("Claude 3.5 Sonnet");
        break;
      case "Translation":
      case "Press Release":
      case "Press Statement":
      case "Makabayan Press Statement":
        setLlmChoice("GPT-4o");
        break;
      // Add more cases here for different tasks as needed
      default:
        // Optionally set a default LLM choice for any task not explicitly handled
        break;
    }
  }, [taskChoice]);

  const handleSummaryStyleChoiceChange = (e) => {
    setSummaryStyleChoice(e.target.value); // Update the state with the new summary style choice
  };

  const handleLanguageChoiceChange = (e) => {
    setLanguageChoice(e.target.value); // Update the state with the new language choice
  };

  const handleTokenCountUpdate = (tokenCount) => {
    setTokenInfo(tokenCount);
  };

  const handleGenerateClick = async () => {
    setIsGenerating(true);

    let usageTaskName = taskChoice;
    if (taskChoice === "Summary/Outline") {
      usageTaskName += `-${summaryStyleChoice}`;
    } else if (taskChoice === "Translation") {
      usageTaskName += `-${languageChoice}`;
    }
    setUsageTask(usageTaskName);
    updateTaskUsage(usageTaskName);

    const taskStateValues = {
      currentTask, // This is your currently selected task type
      prompt, // User's input
      brainstormHistory, // History of brainstorm tasks
      chatHistory, // History of chat tasks
      sessionData, // Session data including token usage, etc.
      setSessionData,
      updateTokenData, // Function to update session data
      summaryStyleChoice, // Include the summary style choice here
    };

    const setTaskStates = {
      setCurrentTask,
      setIsNewChatSession,
      setBrainstormHistory,
      setChatHistory,
      setNonChatDraftHistory,
      setGeneratedText,
      setTokenInfo,
      isNewChatSession,
    };

    // The endpoint could still be unified or specific based on taskChoice;
    // if you're using a unified endpoint, it's okay to keep it as is.
    const unifiedEndpoint = "/generate/";

    // Make sure to pass all necessary information, including summaryStyleChoice,
    // to handleTaskRequest.
    await handleTaskRequest(
      taskChoice, // The type of task, such as "Summary/Outline"
      unifiedEndpoint, // Your API endpoint
      llmChoice, // The choice of language model
      languageChoice, // The selected language (if applicable)
      summaryStyleChoice, // Pass the summary style choice for summary tasks
      setTaskStates, // Object containing state-setting functions
      taskStateValues, // Object containing all task-related values
      exchangeRate
    );

    setIsGenerating(false);
  };

  useEffect(() => {
    if (taskChoice === "Brainstorm") {
      // Set Draft History to the Brainstorm history
      setDraftHistory(brainstormHistory);
    } else if (taskChoice === "Chat") {
      // Set Draft History to the Chat history
      setDraftHistory(chatHistory);
    } else {
      // For other tasks, use the non-chat draft history
      setDraftHistory(nonChatDraftHistory);
    }
  }, [taskChoice, brainstormHistory, chatHistory, nonChatDraftHistory]);

  if (isAuthenticated) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBarComponent
          toggleDrawer={toggleDrawer}
          toggleDashboard={toggleDashboard}
          showHomePage={() => setShowDashboard(false)}
          tokenInfo={tokenInfo}
        />
        <DrawerComponent
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          toggleDrawer={toggleDrawer}
          tokenInfo={tokenInfo}
        />
        {showDashboard ? (
          // Render AdminDashboard if showDashboard is true
          <AdminDashboard />
        ) : (
          // Otherwise, render the homepage content
          <div className="homepage-container">
            <Tabs>
              <Tab label="Write">
                <WriteTab
                  llmChoice={llmChoice}
                  handleLlmChoiceChange={handleLlmChoiceChange}
                  userSelectedLLM={userSelectedLLM}
                  taskChoice={taskChoice}
                  handleTaskChoiceChange={handleTaskChoiceChange}
                  languageChoice={languageChoice}
                  summaryStyleChoice={summaryStyleChoice}
                  handleSummaryStyleChoiceChange={
                    handleSummaryStyleChoiceChange
                  }
                  handleLanguageChoiceChange={handleLanguageChoiceChange}
                  prompt={prompt}
                  setPrompt={setPrompt}
                  handleGenerateClick={handleGenerateClick}
                  isGenerating={isGenerating}
                  generatedText={generatedText}
                  setGeneratedText={setGeneratedText}
                  scratchPadText={scratchPadText}
                  setScratchPadText={setScratchPadText}
                  draftHistory={draftHistory}
                  saveToFile
                />
              </Tab>
              <Tab label="Summarize Articles">
                <ThemeProvider theme={theme}>
                  <SummarizeTab
                    url={url}
                    setUrl={setUrl}
                    setIsGenerating={setIsGenerating}
                    isGenerating={isGenerating}
                    summaryStyle={summaryStyle}
                    setSummaryStyle={setSummaryStyle}
                    urlScratchPadText={urlScratchPadText}
                    setUrlScratchPadText={setUrlScratchPadText}
                    sourceText={sourceText}
                    setSourceText={setSourceText}
                    generatedUrlSummary={generatedUrlSummary}
                    setGeneratedUrlSummary={setGeneratedUrlSummary}
                    urlSummariesHistory={urlSummariesHistory}
                    setUrlSummariesHistory={setUrlSummariesHistory}
                    setTokenInfo={setTokenInfo}
                  />
                </ThemeProvider>
              </Tab>
              <Tab label="Summarize PDF">
                <div className="content-container">
                  <p style={{ fontWeight: "bold" }}>Summarize a long PDF.</p>
                </div>
                <PdfDocSummaryTab
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                  numPages={numPages}
                  setNumPages={setNumPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  scale={scale}
                  setScale={setScale}
                  onTokenCountUpdate={handleTokenCountUpdate}
                  finalSummary={finalSummary}
                  segmentSummaries={segmentSummaries}
                  combinedSummary={combinedSummary}
                  setCombinedSummary={setCombinedSummary}
                  outputHistory={outputHistory}
                  setOutputHistory={setOutputHistory}
                />
              </Tab>

              <Tab label="Chat with PDF">
                <div className="content-container">
                  <p style={{ fontWeight: "bold" }}>
                    Ask questions about the contents of a PDF document.
                  </p>
                </div>
                <TakeNotesTab
                  fileUrl={notesFileUrl}
                  setFileUrl={setNotesFileUrl}
                  numPages={notesNumPages}
                  setNumPages={setNotesNumPages}
                  pageNumber={notesPageNumber}
                  setPageNumber={setNotesPageNumber}
                  scale={notesScale}
                  setScale={setNotesScale}
                  messages={notesMessages}
                  setMessages={setNotesMessages}
                  setTokenInfo={setTokenInfo}
                />
              </Tab>
              <Tab label="Create Image">
                <CreateImageTab
                  model={imageModel}
                  setModel={setImageModel}
                  prompt={imagePrompt}
                  setPrompt={setImagePrompt}
                  imageUrl={generatedImageUrl}
                  setImageUrl={setGeneratedImageUrl}
                  isGenerating={isGeneratingImage}
                  setIsGenerating={setIsGeneratingImage}
                  enhancePrompt={enhancePrompt}
                  setEnhancePrompt={setEnhancePrompt}
                  uploadedImageUrl={uploadedImageUrl}
                  setUploadedImageUrl={setUploadedImageUrl}
                  transformedImageUrl={transformedImageUrl}
                  setTransformedImageUrl={setTransformedImageUrl}
                  task={imageTask}
                  setTask={setImageTask}
                />
              </Tab>
              <Tab label="Transcribe Audio">
                <TranscribeAudioTab
                  task={audioTask}
                  setTask={setAudioTask}
                  language={audioLanguage}
                  setLanguage={setAudioLanguage}
                  file={audioFile}
                  setFile={setAudioFile}
                  audioUrl={audioUrl}
                  setAudioUrl={setAudioUrl}
                  transcriptionResult={transcriptionResult}
                  setTranscriptionResult={setTranscriptionResult}
                  isLoading={isAudioLoading}
                  setIsLoading={setIsAudioLoading}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </Box>
    );
  } else {
    // Render only the LoginForm if not authenticated
    return (
      <Box sx={{ flexGrow: 1 }}>
        <LoginForm />
      </Box>
    );
  }
};

export default HomePage;
