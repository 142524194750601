import React, { useState } from "react";
import apiClient from "../api/apiClient";
import { useAuth } from "../AuthProvider"; // Adjust the import path as needed
import { useExchangeRate } from "../ExchangeRateProvider"; // Correct the path as necessary

import Grid from "@mui/material/Grid";
import { Button, TextField, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { recheckAuth } = useAuth();
  const { fetchExchangeRate } = useExchangeRate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const response = await apiClient.post(
        "/user/login",
        new URLSearchParams({
          username: email,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        console.log("Login successful, rechecking authentication status...");
        await recheckAuth();
        await fetchExchangeRate();
        console.log("Authentication status and exchange rate updated.");
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError(
        error.response?.data?.detail ||
          "An unexpected error occurred during login."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start" // Align items to the start vertically
      style={{ minHeight: "100vh", paddingTop: "10vh" }} // Add padding at the top to push it down a bit
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Box
          sx={{
            backgroundColor: "#282c34",
            padding: "20px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={2}
          >
            <img
              src="/fist3.png"
              alt="icon"
              style={{ height: "40px", marginRight: "10px" }}
            />
            <Typography
              variant="h4"
              style={{ fontWeight: "bolder", fontSize: "30px" }}
            >
              MakGPT
            </Typography>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%",
            }}
          >
            <TextField
              className="myTextField"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              disabled={isLoading}
              variant="outlined"
              fullWidth
            />
            <TextField
              className="myTextField"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              disabled={isLoading}
              variant="outlined"
              fullWidth
            />
            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              color="primary"
              fullWidth
            >
              {isLoading ? "Logging in..." : "Login"}
            </Button>
            {error && <Typography color="error">{error}</Typography>}
            <Typography variant="body2" style={{ marginTop: "20px" }}>
              New user?{" "}
              <Link
                to="/register"
                style={{
                  textDecoration: "none",
                  color: "rgb(140, 210, 240)",
                  fontSize: "14px",
                }}
              >
                Register here.
              </Link>
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoginForm;
