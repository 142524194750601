import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import apiClient from "./api/apiClient";

const ExchangeRateContext = createContext();

export function useExchangeRate() {
  return useContext(ExchangeRateContext);
}

export function ExchangeRateProvider({ children }) {
  const [exchangeRate, setExchangeRate] = useState({ rate: null, timestamp: null });

  const fetchExchangeRate = useCallback(async () => {
    // Check if we need to refetch based on the timestamp
    const now = new Date();
    const shouldFetch = !exchangeRate.timestamp || (new Date(now) - new Date(exchangeRate.timestamp)) / (1000 * 60 * 60) > 24;
    
    if (shouldFetch) {
      try {
        const response = await apiClient.get("/exchange-rate/");
        if (response.status === 200 && response.data.USD_to_PHP_Rate) {
          setExchangeRate({
            rate: response.data.USD_to_PHP_Rate,
            timestamp: now.toISOString(),
          });
        }
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    }
  }, [exchangeRate.timestamp]); // Depend on timestamp alone to avoid unnecessary refetches

  useEffect(() => {
    fetchExchangeRate();
  }, [fetchExchangeRate]);

  // Exposing both exchangeRate data and the fetchExchangeRate method
  const value = { exchangeRate, fetchExchangeRate };

  return <ExchangeRateContext.Provider value={value}>{children}</ExchangeRateContext.Provider>;
}
