import React, { useState, useEffect } from "react";
import apiClient from "../api/apiClient";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function SignupForm() {
  const [step, setStep] = useState(1); // 1: Pre-Screen, 2: Complete Registration
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state for tracking registration success

  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const checkPasswordComplexity = (password) => {
    setPasswordRequirements({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  useEffect(() => {
    checkPasswordComplexity(password);
  }, [password]);

  const isPasswordValid = Object.values(passwordRequirements).every(Boolean);

  const handlePreScreenSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const response = await apiClient.post("/users/pre-screen", { email });

      if (response.status === 200) {
        setStep(2); // Move to the next step
      } else {
        setError(response.data.detail || "Pre-screening failed.");
      }
    } catch (error) {
      console.error("Error during pre-screening:", error);
      setError(error.response?.data?.detail || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompleteRegistrationSubmit = async (event) => {
    event.preventDefault();

    if (!isPasswordValid) {
      setError("Please ensure your password meets all the requirements.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const response = await apiClient.post("/users/complete-registration", {
        email,
        username,
        full_name: fullName,
        password,
      });

      if (response.status === 201) {
        setRegistrationSuccess(true);
      } else {
        setError(response.data.detail || "Registration failed.");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setError(error.response?.data?.detail || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderPasswordRequirements = () => (
    <List dense>
      {Object.entries(passwordRequirements).map(([req, met]) => (
        <ListItem key={req}>
          <ListItemIcon>
            {met ? (
              <CheckCircleOutlineIcon color="success" />
            ) : (
              <CancelOutlinedIcon color="error" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              req === "length"
                ? "At least 8 characters"
                : req === "uppercase"
                ? "At least one uppercase letter"
                : req === "lowercase"
                ? "At least one lowercase letter"
                : req === "number"
                ? "At least one number"
                : "At least one special character"
            }
          />
        </ListItem>
      ))}
    </List>
  );

  if (registrationSuccess) {
    // Show success message and "Proceed to Login" link/button
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start" // Align items to the start vertically
        style={{ minHeight: "100vh", paddingTop: "10vh" }} // Add padding at the top to push it down a bit
      >
        <Grid item xs={12} sm={8} md={6} lg={4} style={{ textAlign: "center" }}>
          <Box
            sx={{
              padding: "20px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" style={{ marginBottom: "20px" }}>
              Registration Successful!
            </Typography>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="primary">
                Proceed to Login
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start" // Align items to the start vertically
        style={{ minHeight: "100vh", paddingTop: "10vh" }} // Add padding at the top to push it down a bit
      >
        <Grid item xs={12} sm={8} md={6} lg={4} style={{ textAlign: "center" }}>
          <Box
            sx={{
              padding: "20px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginBottom={2}
            >
              <img
                src="/fist3.png"
                alt="icon"
                style={{ height: "40px", marginRight: "10px" }}
              />
              <Typography
                variant="h4"
                style={{ fontWeight: "bolder", fontSize: "30px" }}
              >
                MakGPT
              </Typography>
            </Box>
            <Typography variant="h6" style={{ marginBottom: "20px" }}>
              Register
            </Typography>
            {step === 1 && (
              <form
                onSubmit={handlePreScreenSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  width: "100%",
                }}
              >
                <TextField
                  className="myTextField"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                  fullWidth
                  margin="normal"
                  disabled={isLoading}
                />
                <Button
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {isLoading ? "Checking..." : "Next"}
                </Button>
                {error && (
                  <Typography color="error" style={{ marginTop: "20px" }}>
                    {error}
                  </Typography>
                )}
              </form>
            )}
            {step === 2 && (
              <form
                onSubmit={handleCompleteRegistrationSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  width: "100%",
                }}
              >
                <TextField
                  className="myTextField"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  required
                  fullWidth
                  margin="normal"
                  disabled={isLoading}
                />
                <TextField
                  className="myTextField"
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Full Name"
                  required
                  fullWidth
                  margin="normal"
                  disabled={isLoading}
                />
                <TextField
                  className="myTextField"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                  fullWidth
                  margin="normal"
                  disabled={isLoading}
                />
                {password && renderPasswordRequirements()}
                <TextField
                  className="myTextField"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                  fullWidth
                  margin="normal"
                  disabled={isLoading}
                />
                <Button
                  type="submit"
                  disabled={isLoading || !isPasswordValid}
                  style={{ marginTop: "15px" }}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {isLoading ? "Registering..." : "Complete Registration"}
                </Button>
                {error && (
                  <Typography color="error" style={{ marginTop: "20px" }}>
                    {error}
                  </Typography>
                )}
              </form>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default SignupForm;
