import React, { useState } from "react";
import { useFile } from "../FileContext";
import PdfFileUploadButton from "../components/PdfFileUploadButton";
import TextInput from "../components/TextInput";
import PdfViewer from "../components/PdfViewer";
import MyTextArea from "../components/MyTextArea";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { getApiBaseUrl } from "../api/apiClient.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWandMagicSparkles,
  faSpinner,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { uploadPdf, summarizePdfDoc, fetchAndUploadPdf } from "../api/apiCalls";

const PdfDocSummaryTab = ({
    outputHistory,
    setOutputHistory,
    fileUrl,
    setFileUrl,
    numPages,
    setNumPages,
    pageNumber,
    setPageNumber,
    scale,
    setScale,
  }) => {
  const [pageRange, setPageRange] = useState("");
  const { fileState, setFileState } = useFile();
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clickedIcon, setClickedIcon] = useState({ save: false });

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleFetchAndUpload = async () => {
    setIsLoading(true);
    if (!isValidUrl(pdfUrl)) {
      setErrorMessage(
        "The URL provided is invalid. Please enter a correct URL."
      );
      setIsError(true);
      setIsLoading(false);
      return;
    }

    try {
      const result = await fetchAndUploadPdf(pdfUrl);
      setFileState({
        identifier: pdfUrl,
        path: result.path,
      });
      const baseApiUrl = getApiBaseUrl();
      setFileUrl(`${baseApiUrl}/${result.path}`);
      setSummary("");
    } catch (error) {
      console.error("Error in fetching and uploading PDF:", error);
      setErrorMessage("Failed to fetch and upload PDF. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (file) => {
    setIsLoading(true);
    try {
      const uploadResponse = await uploadPdf(file);
      setFileState({
        identifier: file.name,
        path: uploadResponse.path,
      });
      setFileUrl(URL.createObjectURL(file));
      setSummary("");
      setPdfUrl("");
    } catch (error) {
      console.error("Error during file upload:", error);
      setErrorMessage("Failed to upload PDF. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const appendToOutputHistory = (newSummary) => {
    setOutputHistory((prevHistory) => {
      const existingSummaryCount = (prevHistory.match(/Title:/g) || []).length;
      const newSummaryNumber = existingSummaryCount + 1;
      const formattedSummary = `${newSummaryNumber}. ${newSummary}`;
      return prevHistory + formattedSummary + "\n\n";
    });
  };

  const handleSummarize = async () => {
    setIsLoading(true);
    try {
      const summaryResponse = await summarizePdfDoc(fileState.path, pageRange);
      setSummary(summaryResponse.summary);
      appendToOutputHistory(summaryResponse.summary);
    } catch (error) {
      console.error("Error during summarization:", error);
      setErrorMessage("Failed to summarize PDF. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    setClickedIcon({ ...clickedIcon, save: true });
    setTimeout(() => setClickedIcon({ ...clickedIcon, save: false }), 1000);

    // Split the output history by line breaks and extract the first line as the title
    const lines = outputHistory.split("\n");
    const documentTitle = lines[0] || "Document"; // Default to "Document" if the first line is empty

    // Create a timestamp
    const timestamp = new Date().toISOString().replace(/[-T:.]/g, "");

    // Create the filename using the extracted title and the timestamp
    const filename = `${documentTitle}_${timestamp}.txt`;

    // Create a blob from the output history
    const blob = new Blob([outputHistory], { type: "text/plain" });

    // Create a link element to download the blob
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Append the link to the document, trigger click, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const zoomIn = () => setScale((scale) => scale * 1.2);
  const zoomOut = () => setScale((scale) => Math.max(scale * 0.8, 0.5));

  return (
    <Grid container spacing={2} className="pdf-summary-tab-container">
      <Grid item xs={12} md={6}>
        <div className="pdf-summary-tab-left">
          {isLoading && <LinearProgress />}
          <div className="fab-container">
            {isError && (
              <Alert severity="error" onClose={() => setIsError(false)}>
                {errorMessage}
              </Alert>
            )}
            <div className="textarea-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFetchAndUpload();
                }}
              >
                <input
                  type="text"
                  value={pdfUrl}
                  onChange={(e) => {
                    setPdfUrl(e.target.value);
                    if (isError) {
                      setIsError(false);
                      setErrorMessage("");
                    }
                  }}
                  placeholder="Enter PDF url or upload a PDF file"
                  style={{
                    width: "90%",
                    backgroundColor: "#333",
                    color: "white",
                    fontSize: "14px",
                    borderRadius: "8px",
                    marginLeft: "2%",
                    height: "25px",
                    paddingLeft: "6px",
                    border: "1px solid #666",
                  }}
                />
                <button type="submit" className="enter-button">
                  Enter
                </button>
              </form>
            </div>
            <Tooltip title="Select PDF from your local files">
              <span>
                <PdfFileUploadButton onFileUpload={handleFileUpload} />
              </span>
            </Tooltip>
            <Tooltip title="Enter page range (e.g., 1-4) for partial summary. Leave blank to summarize whole document.">
              <span>
                <MyTextArea
                  placeholder="Enter page range"
                  width="130px"
                  backgroundColor="#333"
                  color="white"
                  fontSize="14px"
                  textAlign="center"
                  onChange={(e) => {
                    setPageRange(e.target.value);
                  }}
                />
              </span>
            </Tooltip>
            <div className="icon-large-container">
              {isLoading ? (
                <button
                  className="pdf-icon-button"
                  disabled
                  style={{ cursor: "not-allowed" }}
                >
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="icon-button-large fa-spin"
                  />
                </button>
              ) : (
                <Tooltip title="Summarize PDF">
                  <button className="pdf-icon-button" onClick={handleSummarize}>
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      className="icon-button-large"
                    />
                  </button>
                </Tooltip>
              )}
              <Tooltip title="Save summary">
                <button
                  className={`pdf-icon-button ${
                    clickedIcon.save ? "highlighted" : ""
                  }`}
                  onClick={handleSave}
                >
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    className="icon-button-large"
                  />
                </button>
              </Tooltip>
            </div>
          </div>
          <div>
            {fileUrl && (
              <PdfViewer
                file={fileUrl}
                pageNumber={pageNumber}
                numPages={numPages}
                setPageNumber={setPageNumber}
                scale={scale}
                setScale={setScale}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
              />
            )}
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={6}>
        <div className="pdf-summary-tab-right">
          <TextInput
            label="Summary"
            value={summary}
            placeholder="PDF summary will appear here"
            className="input-container generated-text-container"
            tabContext="PdfSummaryTab"
            readOnly={true}
          />
          <TextInput
            label="Output History"
            placeholder="PDF summaries for this session"
            readOnly={true}
            value={outputHistory}
            className="input-container draft-history-container"
            tabContext="PdfSummaryTab"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default PdfDocSummaryTab;
