import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "grey", // Default color
          "&.Mui-checked": {
            color: "white", // Color when checked
          },
          "&:hover": {
            bgcolor: "rgba(0, 0, 255, 0.04)", // Background color on hover (optional)
          },
        },
      },
    },
  },
});

export default theme;
