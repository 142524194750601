import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import apiClient from "../api/apiClient"; // Adjust the import path based on your project structure

const OrganizationManagement = () => {
  const [organizations, setOrganizations] = useState([]);
  const [newOrgName, setNewOrgName] = useState("");
  const [selectedOrgType, setSelectedOrgType] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);

  useEffect(() => {
    fetchAllOrganizations();
  }, []);

  const fetchAllOrganizations = async () => {
    try {
      const response = await apiClient.get("/organizations/");
      console.log(response.data); // Check the structure of the fetched data
      setOrganizations(response.data);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const orgTypes = ["Partylist", "NDMO", "Others"];

  const addOrganization = async () => {
    try {
      const organizationData = { name: newOrgName, org_type: selectedOrgType };
      await apiClient.post("/admin/add-organization/", organizationData);
      setNewOrgName("");
      setSelectedOrgType(""); // Reset selected org type
      fetchAllOrganizations();
    } catch (error) {
      console.error("Error adding organization:", error);
    }
  };

  const deleteOrganization = async (organizationId) => {
    try {
      await apiClient.delete(`/admin/delete-organization/${organizationId}`);
      fetchAllOrganizations(); // Refresh the list
    } catch (error) {
      console.error("Error deleting organization:", error);
    }
  };

  const handleDeleteClick = (organizationId) => {
    setOrganizationToDelete(organizationId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (organizationToDelete) {
      deleteOrganization(organizationToDelete); // Directly use organizationToDelete as it's the ID
      setOrganizationToDelete(null);
      setDeleteDialogOpen(false);
    }
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h6">Manage Organizations</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop: 2,
          marginBottom: 2,
          "@media (min-width: 600px)": { flexDirection: "row" },
        }}
      >
        <TextField
          className="myTextField"
          label="New Organization Name"
          variant="outlined"
          value={newOrgName}
          onChange={(e) => setNewOrgName(e.target.value)}
        />
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel id="org-type-select-label">Org Type</InputLabel>
          <Select
            style={{
              backgroundColor: "#444",
              color: "white",
              fontSize: "14px",
              borderRadius: "4px",
            }}
            labelId="org-type-select-label"
            id="org-type-select"
            value={selectedOrgType}
            onChange={(e) => setSelectedOrgType(e.target.value)}
            label="Org Type"
          >
            {orgTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={addOrganization}>
          Add Organization
        </Button>
      </Box>
      <List>
        {organizations.map((org) => (
          <ListItem key={org.id} divider>
            <ListItemText
              primary={`${org.name} (${org.org_type})`}
              secondary={`ID: ${org.id}`}
              sx={{
                ".MuiListItemText-primary": { color: "white" }, // Adjust primary text color
                ".MuiListItemText-secondary": { color: "grey" }, // Adjust secondary text color
              }}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteClick(org.id)} // Ensure you pass org.id here
                sx={{ color: "darkgrey" }} // Adjust icon color
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this organization? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrganizationManagement;
