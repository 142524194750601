import React from "react";

const MyTextArea = ({
  onChange,
  placeholder,
  width,
  height,
  backgroundColor,
  color,
  borderRadius,
  fontSize,
  overflow,
}) => {
  const textAreaStyle = {
    borderRadius: borderRadius || "5px",
    width: width || "100%",
    height: height || "20px", // Set a default height
    lineHeight: height || "20px", // Match line height with height for vertical centering
    backgroundColor: backgroundColor || "white",
    color: color || "black",
    fontSize: fontSize || "1em",
    padding: "5px",
    border: "1px solid #777",
    marginTop: "auto",
    marginBottom: "auto",
    resize: "none", // Disabling resizing of the textarea
    textAlign: "left", // Center text horizontally
    overflow: "hidden", // Allow scrolling for longer text
  };

  return (
    <textarea
      onChange={onChange}
      style={textAreaStyle}
      placeholder={placeholder}
      overflow={overflow}
    />
  );
};

export default MyTextArea;
