import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faPaste,
  faFloppyDisk,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import {
  faWandMagicSparkles,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";

const IconToolbar = ({
  isImagePromptBox,
  isPromptBox,
  isGenerating,
  customGenerateButton,
  handleTextClear,
  onGenerateClick,
  handleSaveClick,
  handleCopyClick,
  handlePasteClick,
  readOnly,
  onChange,
  context,
  showSaveIcon,
  textAreaRef, // Pass the textarea ref for direct manipulation
  textValue, // Pass the text value for the copy function
  customButton,
}) => {
  const [clickedIcon, setClickedIcon] = useState({
    clear: false,
    save: false,
    copy: false,
    paste: false,
  });

  const highlightIcon = (iconName) => {
    setClickedIcon({ ...clickedIcon, [iconName]: true });
    setTimeout(
      () => setClickedIcon({ ...clickedIcon, [iconName]: false }),
      1000
    );
  };

  const formatDate = (date) => {
    const pad = (num) => (num < 10 ? `0${num}` : num);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSave = () => {
    saveToFile(context);
  };

  const saveToFile = (context) => {
    const date = new Date();
    const formattedDate = formatDate(date);
    let defaultFileName;

    if (context === "WriteTab") {
      defaultFileName = `MakGPT Write Session ${formattedDate}.txt`;
    } else if (context === "SummarizeTab") {
      defaultFileName = `MakGPT Summarize Articles Session ${formattedDate}.txt`;
    } else {
      // Default case if context is not recognized
      defaultFileName = `MakGPT Session ${formattedDate}.txt`;
    }

    const blob = new Blob([textValue], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = defaultFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleClearClick = () => {
    highlightIcon("clear");
    if (handleTextClear) {
      handleTextClear(); // Call the passed function to clear the text
    }
  };

  const copyToClipboard = () => {
    const textarea = textAreaRef.current;
    if (textarea) {
      const textToCopy =
        textarea.selectionStart !== textarea.selectionEnd
          ? textarea.value.substring(
              textarea.selectionStart,
              textarea.selectionEnd
            )
          : textValue;
      navigator.clipboard.writeText(textToCopy);
    }
  };

  const pasteFromClipboard = async () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      const text = await navigator.clipboard.readText();
      const textarea = textAreaRef.current;
      if (textarea && !readOnly) {
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        const before = textValue.substring(0, startPos);
        const after = textValue.substring(endPos);
        const newValue = before + text + after;

        // Triggering the onChange event of the TextInput component
        // You might need to modify this part based on how you handle state updates in your application
        onChange({ target: { value: newValue } });

        // Updating the cursor position
        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd =
            startPos + text.length;
        }, 0);
      }
    }
  };

  // Function to handle icon rendering with tooltips
  const renderIcons = () => {
    return (
      <>
        {customButton && (
          <span className="custom-button-container">{customButton}</span>
        )}
        {isImagePromptBox && (
          <>
            <Tooltip title="Clear">
              <FontAwesomeIcon
                icon={faTrashCan}
                className={`icon-button ${
                  clickedIcon.clear ? "highlighted" : ""
                }`}
                onClick={handleClearClick}
              />
            </Tooltip>
          </>
        )}
        {isPromptBox && (
          <>
            {customGenerateButton || (
              <FontAwesomeIcon
                icon={isGenerating ? faSpinner : faWandMagicSparkles}
                className="icon-button-generate"
                onClick={isGenerating ? null : onGenerateClick}
                spin={isGenerating}
              />
            )}
            <Tooltip title="Clear">
              <FontAwesomeIcon
                icon={faTrashCan}
                className={`icon-button ${
                  clickedIcon.clear ? "highlighted" : ""
                }`}
                onClick={handleClearClick}
              />
            </Tooltip>
          </>
        )}
        {showSaveIcon && (
          <Tooltip title="Save as text file">
            <FontAwesomeIcon
              icon={faFloppyDisk}
              className={`icon-button ${clickedIcon.save ? "highlighted" : ""}`}
              onClick={() => {
                highlightIcon("save");
                handleSave();
                if (handleSaveClick) handleSaveClick();
              }}
            />
          </Tooltip>
        )}
        <Tooltip title="Copy">
          <FontAwesomeIcon
            icon={faCopy}
            className={`icon-button ${clickedIcon.copy ? "highlighted" : ""}`}
            onClick={() => {
              highlightIcon("copy");
              copyToClipboard();
              if (handleCopyClick) handleCopyClick();
            }}
          />
        </Tooltip>
        {!readOnly && (
          <Tooltip title="Paste">
            <FontAwesomeIcon
              icon={faPaste}
              className={`icon-button ${
                clickedIcon.paste ? "highlighted" : ""
              }`}
              onClick={() => {
                highlightIcon("paste");
                pasteFromClipboard();
                if (handlePasteClick) handlePasteClick();
              }}
            />
          </Tooltip>
        )}
      </>
    );
  };

  return <div className="icon-container">{renderIcons()}</div>;
};

export default IconToolbar;
