import React, { createContext, useContext, useState } from "react";

const TokenTrackerContext = createContext();

export const useTokenTracker = () => useContext(TokenTrackerContext);

export const TokenTrackerProvider = ({ children }) => {
  const [tokenData, setTokenData] = useState({
    totalTokens: 0,
    totalCostPHP: 0,
    totalImageCostPHP: 0,
    totalAudioCostPHP: 0,
    latestImageCostPHP: 0,
    latestAudioCostPHP: 0,
    modelsUsed: {},
    tasksUsed: {},
    lastImageOperation: "",
    lastImageNumImages: 1,
    lastImageInferenceTime: 0,
    imageOperations: {},
    audioOperations: {},
    lastAudioOperation: '',
  });

  const updateTokenData = (newData) => {
    setTokenData((prevData) => {
      // Handle LLM data
      const newTokens = newData.total_tokens || 0;
      const newCostPHP = newData.total_cost_php || 0;

      // Handle image data
      const newImageCostPHP = newData.imageCostPHP || 0;

      // Handle audio data
      const newAudioCostPHP = newData.audioCostPHP || 0;

      // Determine the model name
      const modelName = newData.model_name || newData.modelName || "Unknown";

      // Update modelsUsed
      const updatedModelsUsed = { ...prevData.modelsUsed };
      if (newData.audioCostPHP) {
        updatedModelsUsed["Whisper v3"] = (updatedModelsUsed["Whisper v3"] || 0) + 1;
      } else {
        updatedModelsUsed[modelName] = (updatedModelsUsed[modelName] || 0) + 1;
      }

      // Update tasksUsed
      const updatedTasksUsed = { ...prevData.tasksUsed };
      if (newData.task) {
        updatedTasksUsed[newData.task] = (updatedTasksUsed[newData.task] || 0) + 1;
      }

      // Update imageOperations
      const updatedImageOperations = { ...prevData.imageOperations };
      if (newData.operation === 'Create Image' || newData.operation === 'Transform Image') {
        updatedImageOperations[newData.operation] = (updatedImageOperations[newData.operation] || 0) + 1;
      }

      // Update audioOperations
      const updatedAudioOperations = { ...prevData.audioOperations };
      if (newData.audioOperation) {
        updatedAudioOperations[newData.audioOperation] = (updatedAudioOperations[newData.audioOperation] || 0) + 1;
      }

      return {
        ...prevData,
        totalTokens: prevData.totalTokens + newTokens,
        totalCostPHP: prevData.totalCostPHP + newCostPHP + newImageCostPHP + newAudioCostPHP,
        totalImageCostPHP: prevData.totalImageCostPHP + newImageCostPHP,
        totalAudioCostPHP: prevData.totalAudioCostPHP + newAudioCostPHP,
        latestImageCostPHP: newData.latestImageCostPHP || prevData.latestImageCostPHP,
        latestAudioCostPHP: newAudioCostPHP || prevData.latestAudioCostPHP,
        modelsUsed: updatedModelsUsed,
        tasksUsed: updatedTasksUsed,
        lastImageOperation: newData.operation || prevData.lastImageOperation,
        lastImageNumImages: newData.numImages || prevData.lastImageNumImages,
        lastImageInferenceTime: newData.inferenceTime || prevData.lastImageInferenceTime,
        imageOperations: updatedImageOperations,
        audioOperations: updatedAudioOperations,
        lastAudioOperation: newData.audioOperation || prevData.lastAudioOperation,
      };
    });
  };

  return (
    <TokenTrackerContext.Provider value={{ tokenData, updateTokenData }}>
      {children}
    </TokenTrackerContext.Provider>
  );
};