import React, { useEffect, useRef } from "react";
import IconToolbar from "./IconToolbar"; // Import IconToolbar component

const ImagePromptBox = ({
  label,
  placeholder,
  onChange,
  className,
  value,
  readOnly,
  isPromptBox,
  isImagePromptBox,
  isGenerating,
  onGenerateClick,
  handleClearClick,
  tabContext,
  showSaveIcon,
}) => {
  const textAreaRef = useRef(null);
  const minTextAreaHeight = 120; // Minimum height in pixels

  const adjustHeight = () => {
    if (textAreaRef.current) {
      // Reset the height to 'auto' to allow textarea to shrink to fit content
      textAreaRef.current.style.height = "auto";

      const scrollHeight = textAreaRef.current.scrollHeight;
      const newHeight = Math.max(scrollHeight, minTextAreaHeight);
      textAreaRef.current.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  const handleInput = (e) => {
    if (!readOnly && onChange) {
      onChange(e);
    }
    adjustHeight();
  };

  const handleTextClear = () => {
    // Assuming 'value' is the state for the text in the textarea and 'onChange' is the method to update it
    onChange({ target: { value: "" } });
  };

  // Reset height when content is cleared
  useEffect(() => {
    if (value === "") {
      textAreaRef.current.style.height = `${minTextAreaHeight}px`;
    }
  }, [value]);

  return (
    <div className={className} style={{ position: "relative" }}>
      {label && <label className="input-label">{label}</label>}

      <IconToolbar
        isPromptBox={isPromptBox}
        isImagePromptBox={isImagePromptBox}
        isGenerating={isGenerating}
        onGenerateClick={onGenerateClick}
        handleTextClear={handleTextClear}
        handleClearClick={handleClearClick}
        textAreaRef={textAreaRef} // Pass the ref to the toolbar
        textValue={value} // Pass the current value of the text input
        context={tabContext}
        handleCopyClick={() => {
          /* logic for copying */
        }}
        handlePasteClick={() => {
          /* logic for pasting */
        }}
        readOnly={readOnly}
        onChange={onChange}
        showSaveIcon={showSaveIcon}
      />
      <textarea
        ref={textAreaRef}
        className="text-input"
        placeholder={placeholder}
        onChange={handleInput}
        style={{ minHeight: `${minTextAreaHeight}px` }}
        value={value}
        readOnly={readOnly}
      />
    </div>
  );
};

export default ImagePromptBox;
