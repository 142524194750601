import React, { useRef } from "react";
import Fab from "@mui/material/Fab";

const PdfFileUploadButton = ({ onFileUpload }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileUpload(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        size="medium"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="application/pdf"
        onChange={handleFileChange}
      />
      <Fab variant="extended" size="medium" onClick={handleButtonClick}>
        Upload PDF
      </Fab>
    </div>
  );
};

export default PdfFileUploadButton;
