// src/TaskContext.js
import React, { createContext, useState, useContext } from 'react';

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const [usageTask, setUsageTask] = useState('');
  const [taskUsage, setTaskUsage] = useState({});

  const updateTaskUsage = (task) => {
    setTaskUsage(prevUsage => ({
      ...prevUsage,
      [task]: (prevUsage[task] || 0) + 1
    }));
  };

  return (
    <TaskContext.Provider value={{ usageTask, setUsageTask, taskUsage, updateTaskUsage }}>
      {children}
    </TaskContext.Provider>
  );
};

export const useTask = () => useContext(TaskContext);