import React, { useState } from "react";
import { useSessionData } from "../SessionDataProvider";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useTokenTracker } from "../TokenTrackerContext";
import { useTask } from '../TaskContext';
import { getApiBaseUrl } from "../api/apiClient"; // Adjust the path as necessary
import Box from "@mui/material/Box";
import PdfFileUploadButton from "../components/PdfFileUploadButton"; // Import the new component
import PdfViewer from "../components/PdfViewer";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

// eslint-disable-next-line no-unused-vars
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

import { fetchAndUploadPdf } from "../api/apiCalls";
import { uploadPdf } from "../api/apiCalls";
import { processQuery } from "../api/apiCalls";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";

const TakeNotesTab = ({
  fileUrl: notesFileUrl,
  setFileUrl: setNotesFileUrl,
  numPages: notesNumPages,
  setNumPages: setNotesNumPages,
  pageNumber: notesPageNumber,
  setPageNumber: setNotesPageNumber,
  scale: notesScale,
  setScale: setNotesScale,
  messages,
  setMessages,
  setTokenInfo,
}) => {
  const [selectedFile, setSelectedFile] = React.useState(null); // State to store the selected file
  const [pdfUrl, setPdfUrl] = useState(""); // State variable to store the PDF URL
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isTyping, setIsTyping] = useState(false);

  const [k] = useState(3); // default value for relevant segments
  const [chunkSize] = useState(200); // default value for tokens per segment

  const { sessionData, setSessionData } = useSessionData();
  const { exchangeRate } = useExchangeRate();
  const { updateTokenData } = useTokenTracker();
  const { setUsageTask, updateTaskUsage } = useTask();

  const [inputMessage, setInputMessage] = useState("");
  const [uploadedFilename, setUploadedFilename] = useState("");
  const [processedUrls, setProcessedUrls] = useState({});

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleFetchAndUpload = async () => {
    // Validate the URL before proceeding
    if (!isValidUrl(pdfUrl)) {
      setErrorMessage(
        "The URL provided is invalid. Please enter a correct URL."
      );
      setIsError(true);
      return;
    }

    try {
      const result = await fetchAndUploadPdf(pdfUrl);

      // Use getApiBaseUrl to dynamically determine the API base URL
      const baseApiUrl = getApiBaseUrl();
      const uploadedPdfUrl = `${baseApiUrl}/${result.path}`;
      setNotesFileUrl(uploadedPdfUrl); // Set this URL for UI display
      setUploadedFilename(result.filename);

      // Handle the result as needed
      setIsError(false); // Assuming you want to reset the error state on success
    } catch (error) {
      console.error("Error in fetching and uploading PDF:", error);
      setIsError(true);
      setErrorMessage("Error fetching and uploading PDF.");
    }
  };

  const handleFileUpload = (file) => {
    if (file) {
      setSelectedFile(file); // Store the file in state
      setNotesFileUrl(URL.createObjectURL(file)); // Set URL for UI display
      setUploadedFilename(file.name);
      // Clear Final Summary and Segment Summaries if not empty
      setPdfUrl("");
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNotesNumPages(numPages);
  };

  const zoomIn = () => setNotesScale((scale) => scale * 1.2);
  const zoomOut = () => setNotesScale((scale) => Math.max(scale * 0.8, 0.5));

  const handleChange = (innerHtml, textContent, innerText, nodes) => {
    setInputMessage(textContent);
  };

  const handleUserQuery = async (userQuery) => {
    setIsTyping(true);

    if (!selectedFile && !isValidUrl(pdfUrl)) {
      console.error("No PDF file selected or URL provided.");
      setErrorMessage("No PDF file selected or URL provided.");
      setIsError(true);
      return;
    }

    let filePath;

    try {
      const usageTaskName = "Chat with PDF";
      setUsageTask(usageTaskName);
      updateTaskUsage(usageTaskName);

      if (selectedFile) {
        // If a file is uploaded, process it to get the file path
        const uploadResponse = await uploadPdf(selectedFile);
        if (!uploadResponse || !uploadResponse.path) {
          console.error("Invalid upload response:", uploadResponse);
          return;
        }
        filePath = uploadResponse.path;
      } else if (pdfUrl && !processedUrls[pdfUrl]) {
        // If a PDF URL is provided and not already processed
        const result = await fetchAndUploadPdf(pdfUrl);
        if (!result || !result.path) {
          console.error(
            "Error in fetching and uploading PDF from URL:",
            result
          );
          return;
        }
        filePath = result.path;
        // Store the processed URL and its path
        setProcessedUrls((prev) => ({ ...prev, [pdfUrl]: filePath }));
      } else if (pdfUrl && processedUrls[pdfUrl]) {
        // Use the previously stored file path for this URL
        filePath = processedUrls[pdfUrl];
      }

      // Proceed with processing the query using the determined filePath
      const response = await processQuery({
        file_path: filePath,
        query: userQuery,
        session_data: sessionData, // Ensure this includes the expected structure
        usd_to_php_rate: exchangeRate.rate, // Use the current exchange rate
        k: parseInt(k, 10),
        chunk_size: parseInt(chunkSize, 10),
      });

      // Update messages and token information
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: userQuery,
          sender: "User",
          sentTime: new Date().toLocaleString(),
        },
        {
          message: response.answer,
          sender: "System",
          sentTime: new Date().toLocaleString(),
        },
      ]);

      // Directly use the formatted token information from the API response for display
      if (response.token_count) {
        setTokenInfo(response.token_count); // Direct display in UI
      }

      // Update the TokenTrackerContext for cumulative tracking
      if (response.token_count) {
        const newTokenData = {
          totalTokens: response.token_count.total_tokens,
          totalCostUSD: response.token_count.total_cost_usd,
          totalCostPHP: response.token_count.total_cost_php,
          modelName: response.token_count.model_name, // Adjust based on actual response structure
        };

        updateTokenData(newTokenData); // Cumulative update for the context
      }

      if (response.session_data) {
        setSessionData(response.session_data);
      }
    } catch (error) {
      setErrorMessage("Error processing query.");
      setIsError(true);
    } finally {
      setIsTyping(false);
    }
  };

  const handleSave = () => {
    const chatContent = messages
      .map((msg) => {
        return `[${msg.sentTime}] ${msg.sender}: ${msg.message}`;
      })
      .join("\n");

    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const saveFileName = `${uploadedFilename}_${timestamp}.txt`;

    const element = document.createElement("a");
    const file = new Blob([chatContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = saveFileName;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Box sx={{ flexGrow: 1, height: "calc(100vh - 64px)" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className="pdf-takenotes-tab-left">
            <div
              className="fab-container"
              style={{ marginTop: "20px", marginLeft: "0px" }}
            >
              {/* Alert for Error Message */}
              {isError && (
                <Alert
                  severity="error"
                  onClose={() => setIsError(false)} // Adds a close icon to the alert
                >
                  {errorMessage}
                </Alert>
              )}
              <div className="textarea-container">
                <form
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submit action
                    handleFetchAndUpload();
                  }}
                >
                  <input
                    type="text"
                    value={pdfUrl}
                    onChange={(e) => {
                      setPdfUrl(e.target.value);
                      if (isError) {
                        setIsError(false);
                        setErrorMessage("");
                      }
                    }}
                    placeholder="Enter PDF url or upload a PDF file"
                    style={{
                      width: "90%",
                      backgroundColor: "#333",
                      color: "white",
                      fontSize: "14px",
                      borderRadius: "8px",
                      marginLeft: "2%",
                      height: "25px",
                      paddingLeft: "6px",
                      border: "1px solid #666",
                    }}
                  />
                  <button type="submit" className="enter-button">
                    Enter
                  </button>
                </form>
              </div>
              <Tooltip title="Select PDF from your local files">
                <span>
                  <PdfFileUploadButton onFileUpload={handleFileUpload} />
                </span>
              </Tooltip>

              <Tooltip title="Save chat">
                <button className="pdf-icon-button" onClick={handleSave}>
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    className="icon-button-large"
                  />
                </button>
              </Tooltip>
            </div>
            {notesFileUrl && (
              <PdfViewer
                file={notesFileUrl}
                pageNumber={notesPageNumber}
                numPages={notesNumPages}
                setPageNumber={setNotesPageNumber}
                scale={notesScale}
                setScale={setNotesScale}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            className="pdf-takenotes-tab-right"
            style={{ position: "relative", height: "500px" }}
          >
            <MainContainer
              style={{
                border: "1px solid #444",
                backgroundColor: "#282c34",
                overflow: "hidden",
                borderRadius: "8px",
                height: "145%",
                width: "90%",
                marginTop: "20px",
              }}
            >
              <ChatContainer
                style={{
                  border: "0px solid #cccccc",
                  backgroundColor: "#282c34",
                  marginTop: "10px",
                }}
              >
                <MessageList style={{ backgroundColor: "#282c34" }}>
                  {messages.length > 0 ? (
                    messages.map((msg, index) => (
                      <Message key={index} model={msg} />
                    ))
                  ) : (
                    <div
                      style={{
                        color: "#aaa",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      Enter your query in the chatbox below
                    </div>
                  )}
                  {isTyping && (
                    <TypingIndicator
                      content="MakGPT is typing"
                      style={{
                        backgroundColor: "#444",
                        borderRadius: "4px",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </MessageList>
                <MessageInput
                  style={{
                    border: "0px solid #cccccc",
                    backgroundColor: "#282c34",
                    marginTop: "3%",
                    marginBottom: "3%",
                  }}
                  attachButton={false}
                  value={inputMessage}
                  onChange={handleChange}
                  onSend={() => {
                    handleUserQuery(inputMessage);
                    setInputMessage("");
                  }}
                  placeholder="Type query here"
                />
              </ChatContainer>
            </MainContainer>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TakeNotesTab;