import React, { createContext, useState, useContext } from "react";

const FileContext = createContext();

export const FileProvider = ({ children }) => {
  const [fileState, setFileState] = useState({
    identifier: null, // The unique filename or path as the identifier
    path: null, // Server-side path to the file for processing
    // Optional: status, if you decide it's necessary for UI feedback or error handling
  });

  // Provide both the state and its updater function to children
  return (
    <FileContext.Provider value={{ fileState, setFileState }}>
      {children}
    </FileContext.Provider>
  );
};

// Custom hook to use the file context
export const useFile = () => useContext(FileContext);
