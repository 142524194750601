import axios from "axios";

// Function to dynamically determine the API base URL
export const getApiBaseUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    // For local development, assuming the backend runs on localhost:8000
    return "http://localhost:8000";
  } else {
    // Specify the full URL of your deployed backend
    return "https://api.pasulong.cc";
  }
};

// Use the dynamically determined API base URL for the axios client
const apiClient = axios.create({
  baseURL: getApiBaseUrl(),
  withCredentials: true,
  // other configurations as needed
});

export default apiClient;
