import React, { useState } from "react";
import { useSessionData } from "../SessionDataProvider";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useTask } from "../TaskContext";
import UrlInputBox from "../components/UrlInputBox";
import StyledButton from "../components/StyledButton";
import TextBox from "../components/TextBox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { createPowerPoint } from "../api/apiCalls";

import { fetchSummary } from "../api/apiCalls"; // Import fetchSummary function
import Grid from "@mui/material/Grid";
const SummarizeTab = ({
  url,
  setUrl,
  summaryStyle,
  setSummaryStyle,
  handleClearClick, // Ensure this is used or passed down correctly
  isGenerating,
  setIsGenerating,
  generatedUrlSummary,
  setGeneratedUrlSummary,
  sourceText,
  setSourceText,
  urlScratchPadText,
  setUrlScratchPadText,
  urlSummariesHistory,
  setUrlSummariesHistory,
  setTokenInfo, // Ensure this prop is provided
}) => {
  const { sessionData, setSessionData } = useSessionData();
  const { exchangeRate } = useExchangeRate();
  const { setUsageTask, updateTaskUsage } = useTask();
  const usd_to_php_rate = exchangeRate.rate;
  const [isPptxGenerating, setIsPptxGenerating] = useState(false);

  const handleStyleChange = (event) => {
    setSummaryStyle(event.target.value);
  };

  const handleSummarizeUrl = async () => {
    setIsGenerating(true);
    try {
      const usageTaskName = `Summarize URL-${summaryStyle}`;
      setUsageTask(usageTaskName);
      updateTaskUsage(usageTaskName);

      // Pass sessionData and usd_to_php_rate to the fetchSummary call
      const summaryData = await fetchSummary(
        url,
        summaryStyle,
        sessionData,
        usd_to_php_rate
      );
      setGeneratedUrlSummary(summaryData.summary);
      setSourceText(summaryData.source_text);

      // Update tokenInfo and sessionData with the response
      setTokenInfo(summaryData.token_info); // Assume this updates local state or context
      setSessionData(summaryData.session_data); // Update session data with new data from backend

      // Append new summary to history with a separator for subsequent entries
      setUrlSummariesHistory((prevHistory) => {
        const separator = prevHistory ? "--------\n\n" : ""; // Add separator only if there is existing history
        const newHistoryEntry = `${separator}Summary:\n${summaryData.summary}`;
        return prevHistory + newHistoryEntry;
      });
    } catch (error) {
      console.error("Error:", error);
      // Optionally, update the UI to reflect the error
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSaveAsPPTX = async () => {
    setIsPptxGenerating(true);
    try {
      const pptxBlob = await createPowerPoint(urlScratchPadText);

      // Create a link element, set the download attribute, and click it
      const fileURL = URL.createObjectURL(pptxBlob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "generated_presentation.pptx");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error("Error creating PowerPoint:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsPptxGenerating(false);
    }
  };

  return (
    <Grid container spacing={2} className="content-container">
      <Grid item xs={12} className="main-content-header">
        <p>Summarize online articles.</p>
      </Grid>
      <Grid item xs={12}>
        <UrlInputBox
          label="Enter URLs"
          placeholder="Enter one or several URLs of online articles, choose summary style, then click the SUMMARIZE button."
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="url-input-container"
          multiline
          tabContext="SummarizeTab"
          handleClearClick={handleClearClick}
          isGenerating={isGenerating}
          isPromptBox={true}
          customGenerateButton={
            <StyledButton
              onClick={handleSummarizeUrl}
              isGenerating={isGenerating}
            >
              Summarize
            </StyledButton>
          }
        />
      </Grid>
      <Grid item xs={12} className="summary-style-container">
      <FormControl
  component="fieldset"
  className="radioGroup"
  sx={{ marginLeft: 1 }}
>
  <RadioGroup
    row
    aria-label="summary style"
    name="summaryStyle"
    value={summaryStyle}
    onChange={handleStyleChange}
    className="radioGroup"
    sx={{ justifyContent: "flex-start", alignItems: "flex-start" }} // Added alignItems property
  >
    <FormControlLabel
      value="BulletPoints"
      control={<Radio />}
      label="Bullets"
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          color: "white",
        },
      }}
    />
    <FormControlLabel
      value="Powerpoint"
      control={<Radio className="radioControl" />}
      label="Powerpoint"
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          color: "white",
        },
      }}
    />
    <FormControlLabel
      value="NotesAndInsights"
      control={<Radio className="radioControl" />}
      label="Notes & Insights"
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          color: "white",
        },
      }}
    />
    <FormControlLabel
      value="Narrative"
      control={<Radio className="radioControl" />}
      label="Narrative"
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          color: "white",
        },
      }}
    />
  </RadioGroup>
</FormControl>

      </Grid>
      <Grid container item spacing={2} xs={12}>
        <Grid item md={6} xs={12}>
          <TextBox
            label="Summary"
            placeholder="Summary text will appear here."
            value={generatedUrlSummary}
            onChange={(e) => setGeneratedUrlSummary(e.target.value)}
            className="input-container generated-text-container"
            tabContext="SummarizeTab"
            showSaveIcon={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextBox
            label="Source Text"
            placeholder="Generated text will appear here."
            value={sourceText}
            onChange={(e) => setSourceText(e.target.value)}
            className="input-container generated-text-container"
            tabContext="SummarizeTab"
            showSaveIcon={false}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} xs={12}>
        <Grid item md={6} xs={12}>
          <TextBox
            label="Scratch Pad"
            placeholder="Collate and edit here. Save as text file or Powerpoint file. For Powerpoint, text should follow <heading + bullet points> per slide."
            value={urlScratchPadText}
            onChange={(e) => setUrlScratchPadText(e.target.value)}
            className="input-container scratch-pad-container"
            tabContext="SummarizeTab"
            showSaveIcon={true}
            customButton={
              <Tooltip title="Save as PPTX file">
                <span>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveAsPPTX}
                    disabled={isPptxGenerating || !urlScratchPadText.trim()}
                    isGenerating={isPptxGenerating}
                    style={{
                      marginRight: "10px",
                      padding: "5px 10px",
                      fontSize: "0.8rem",
                    }}
                  >
                    {isPptxGenerating && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        className="spinner"
                      />
                    )}
                    <span className="button-text">PPTX</span>
                  </StyledButton>
                </span>
              </Tooltip>
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextBox
            label="Output History"
            placeholder="Generated summaries for this session."
            value={urlSummariesHistory}
            onChange={(e) => setUrlSummariesHistory(e.target.value)}
            readOnly={true}
            className="input-container draft-history-container"
            tabContext="SummarizeTab"
            showSaveIcon={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummarizeTab;
