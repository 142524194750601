// StyledButton.js
import React from 'react';
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const StyledButtonBase = styled(Button)(({ isGenerating }) => ({
  marginBottom: "10px",
  marginRight: "8px",
  borderRadius: "8px",
  fontSize: "12px",
  paddingTop: "0px",
  paddingBottom: "0px",
  paddingLeft: "10px",
  paddingRight: "10px",
  position: "relative",
  "&:disabled": {
    backgroundColor: "rgba(63, 81, 181, 0.5)", // Dimmed primary color
    color: "rgba(255, 255, 255, 0.5)", // Dimmed text color
  },
  "& .spinner": {
    position: "absolute",
    left: "45%",
    top: "25%",
    transform: "translate(-50%, -50%)",
    color: "white", // Keep spinner white
  },
  "& .button-text": {
    visibility: isGenerating ? "hidden" : "visible",
  },
}));

const StyledButton = ({ onClick, isGenerating, children }) => (
  <StyledButtonBase
    variant="contained"
    color="primary"
    onClick={onClick}
    disabled={isGenerating}
    isGenerating={isGenerating}
  >
    {isGenerating && (
      <FontAwesomeIcon icon={faSpinner} spin className="spinner" />
    )}
    <span className="button-text">{children}</span>
  </StyledButtonBase>
);

export default StyledButton;