import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import HomePage from "./pages/HomePage";
import SignupForm from "./pages/RegisterForm";
import { AuthProvider } from "./AuthProvider"; // Adjust the path as necessary
import { ExchangeRateProvider } from "./ExchangeRateProvider";
import { SessionDataProvider } from "./SessionDataProvider"; // Adjust the path as necessary
import { TokenTrackerProvider } from "./TokenTrackerContext"; // Add the correct path
import { FileProvider } from "./FileContext"; // Import the FileProvider
import { TaskProvider } from "./TaskContext";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ExchangeRateProvider>
          <SessionDataProvider>
            <TokenTrackerProvider>
              <TaskProvider>
                <FileProvider>
                  {" "}
                  {/* Wrap your application or the part that needs file state with FileProvider */}
                  <MainLayout>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/register" element={<SignupForm />} />
                      <Route path="*" element={<div>Page not found</div>} />
                      {/* Add more routes as needed */}
                    </Routes>
                  </MainLayout>
                </FileProvider>
              </TaskProvider>
            </TokenTrackerProvider>
          </SessionDataProvider>
        </ExchangeRateProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
