import React, { createContext, useContext, useState, useEffect } from "react";
import apiClient from "./api/apiClient"; // Adjust the import path based on your project structure

// Creating the context for auth information
export const AuthContext = createContext();

// Custom hook for consuming context
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userName, setUserName] = useState(null);

  async function checkAuthStatus() {
    try {
      // Directly using apiClient to make the GET request
      const response = await apiClient.get("/user/auth-status");
      const userData = response.data; // With Axios, response data is accessed via `response.data`
      setIsAuthenticated(true);
      setUser(userData);
      setUserRole(userData.role); // Assuming the response contains a 'role' property
      setUserName(userData.username);
    } catch (error) {
      console.error("Error checking authentication status:", error);
      setIsAuthenticated(false);
      setUser(null);
      setUserRole(null); // Reset the user role
      setUserName(null);
    }
  }

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    user,
    userRole, // Make sure to export the userRole in your context value
    userName,
    recheckAuth: checkAuthStatus,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
