import React, { useState } from "react"; // Import useState here
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import { useAuth } from "../AuthProvider";
import { logoutUser } from "../api/apiCalls";
import DrawerComponent from "./DrawerComponent";

const AppBarComponent = ({
  tokenInfo,
  toggleDashboard,
  showHomePage,
  toggleDrawer,
}) => {
  const { isAuthenticated, setIsAuthenticated, user } = useAuth(); // Destructure `user` to access user details
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleToggleDrawer = (open) => () => {
    // Renamed to avoid confusion
    setDrawerOpen(open);
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      setIsAuthenticated(false);
      // Remove the token from storage
      localStorage.removeItem("token"); // Adjust depending on where you store the token
      window.location.reload(); // Force a full page reload
      navigate("/"); // Redirect to login page or another route
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "slategray", width: "100%" }}
      >
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="sidepanel"
              sx={{ mr: 2 }}
              onClick={handleToggleDrawer(true)}
            >
             <ViewSidebarOutlinedIcon fontSize="25px" sx={{ transform: 'scaleX(-1)' }} />
            </IconButton>
          )}
          <div
            onClick={showHomePage}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src="/fist3.png"
              alt="icon"
              style={{
                height: "35px",
                marginRight: "10px",
                marginLeft: "-5px",
              }}
            />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "bolder",
                fontSize: "35px",
                marginTop: "0px",
              }}
            >
              MakGPT
            </Typography>
          </div>
          <Box display="flex" marginLeft="auto">
            {isAuthenticated && user?.role === "admin" && (
              <Tooltip title="Dashboard">
                <IconButton size="large" color="inherit" onClick={toggleDashboard}>
                  <AdminPanelSettingsOutlinedIcon fontSize="25px" />
                </IconButton>
              </Tooltip>
            )}
            {isAuthenticated && (
              <Tooltip title="Logout">
                <IconButton size="large" color="inherit" onClick={handleLogout}>
                  <LogoutIcon fontSize="25px"/>
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <DrawerComponent
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          tokenInfo={tokenInfo}
        />
      )}
    </>
  );
};

export default AppBarComponent;
