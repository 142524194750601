import React, { createContext, useContext, useState } from 'react';

const SessionDataContext = createContext();

export const useSessionData = () => useContext(SessionDataContext);

export const SessionDataProvider = ({ children }) => {
    const [sessionData, setSessionData] = useState({
        total_tokens: 0,
        total_cost_usd: 0,
        total_cost_php: 0,
        models_used: {},
    });

    return (
        <SessionDataContext.Provider value={{ sessionData, setSessionData }}>
            {children}
        </SessionDataContext.Provider>
    );
};
